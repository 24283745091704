import * as React from "react";

export default function MisionCard({ icon, text, typeFlex }) {
  return (
    <article className="flex shadow-lg rounded-lg w-full h-[15vh] sm:h-[25vh] items-center border-2 border-rgb-navbar hover:border-red-800 hover:bg-red-500 bg-white sm:bg-white cursor-pointer">
      <section
        className={`flex ${typeFlex} w-full py-2 px-2 lg:px-6 items-center gap-y-2 sm:gap-y-4`}
      >
        <div
          type="button"
          className="flex p-3 sm:p-2 w-[8vh] h-[8vh] sm:w-[10vh] sm:h-[10vh] items-center justify-center rounded-full bg-zinc-200 shadow-xl "
        >
          <img
            src={icon}
            style={{ width: "60px", height: "60px" }}
            alt="Politica integral"
          />
        </div>
        <div className="flex px-4 font-bold text-center text-black hover:text-white sm:text-justify text-base sm:text-lg sm:text-xl lg:text-2xl">
          <p>{text}</p>
        </div>
      </section>
    </article>
  );
}
