import inicioIcon from '../assets/icons/sidebar/inicio.svg';
import serviciosIcon from '../assets/icons/sidebar/servicios.svg';
import normasIcon from '../assets/icons/sidebar/normas.svg';
import contactenosIcon from '../assets/icons/sidebar/contactenos.svg';

export const utilities = [
  { name: "Inicio", to: "/" },
  { name: "Servicios", to: "/servicios" },
  { name: "Normas de seguridad", to: "/normas-de-seguridad" },
  { name: "Contáctenos", to: "/contactenos" },
];

export const mobileUtilities = [
  { icon: inicioIcon, name: "Inicio", to: "/" },
  { icon: serviciosIcon, name: "Servicios", to: "/servicios" },
  { icon: normasIcon, name: "Normas de seguridad", to: "/normas-de-seguridad" },
  { icon: contactenosIcon, name: "Contáctenos", to: "/contactenos" },
];


export const services = [
  { name: "Transporte Empresarial", to: "/servicios" },
  { name: "Transporte de Salud", to: "/servicios" },
  { name: "Transporte Escolar", to: "/servicios" },
  { name: "Transporte de Turismo", to: "/servicios" },
  { name: "Transporte Para eventos particulares", to: "/servicios" },

]