import img1 from '../assets/images/Slides/carro1.jpeg';
import img2 from '../assets/images/Slides/carro2.jpeg';
import img3 from '../assets/images/Slides/carro3.jpeg';
import img4 from '../assets/images/Slides/carro4.jpeg';

export const ImagesSlides = [
    {id: 1, imgURL: img1, imgAlt: "img-1"},
    {id: 2, imgURL: img2, imgAlt: "img-2"},
    {id: 3, imgURL: img3, imgAlt: "img-3"},
    {id: 4, imgURL: img4, imgAlt: "img-4"},
]