/* eslint-disable */
import About from "../pages/about/About";
import Services from "../pages/servicePage/ServicePage";
import RulesPage from "../pages/rulesPage/RulesPage";
import Contact from "../pages/contact/Contact";

export const routes = [
  {
    path: "/",
    name: "Inicio",
    element: About,
  },
  {
    path: "/servicios",
    name: "servicios",
    element: Services,
  },
  {
    path: "/normas-de-seguridad",
    name: "normas-de-seguridad",
    element: RulesPage,
  },
  {
    path: "/contactenos",
    name: "contactenos",
    element: Contact,
  },
];

