/* eslint-disable */

export default function SocialMedia({ typeflex, smtypeflex }) {
  return (
    <ul className="flex flex-col sm:flex-row py-6">
      <li className={`flex ${typeflex} ${smtypeflex} gap-x-10 sm:gap-x-20 gap-y-5 justify-center`}>
        <section className="flex flex-col sm:flex-row gap-y-2 sm:gap-x-10 items-center">
          <div className="flex flex-row gap-x-2 items-center">
            <a href="https://wa.me/573214868249" aria-label="Find us on whatsApp" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 432 432">
                <path 
                  d="M364.5 65Q427 127 427 214.5T364.5 364T214 426q-54 0-101-26L0 429l30-109Q2 271 2 214q0-87 62-149T214 3t150.5 62M214 390q73 0 125-51.5T391 214T339 89.5T214 38T89.5 89.5T38 214q0 51 27 94l4 6l-18 65l67-17l6 3q42 25 90 25m97-132q9 5 10 7q4 6-3 25q-3 8-15 15.5t-21 9.5q-18 2-33-2q-17-6-30-11q-8-4-15.5-8.5t-14.5-9t-13-9.5t-11.5-10t-10.5-10.5t-8.5-9.5t-7-8.5t-5.5-7t-3.5-5L128 222q-22-29-22-55q0-24 19-44q6-7 14-7q6 0 10 1q8 0 12 9q2 3 6 13l7 17.5l3 8.5q3 5 1 9q-3 7-5 9l-3 3l-3 3.5l-2 2.5q-6 6-3 11q13 22 30 37q13 11 43 26q7 3 11-1q12-15 17-21q4-6 12-3q6 3 36 17"
                  fill="#62a330" 
                />
              </svg>
            </a>
            <a href="https://wa.me/573214868249"><span className="text-base sm:text-lg hover:text-rgb-navbar cursor-pointer">3214868249</span></a>
          </div>
          <div className="flex flex-row gap-x-2 items-center">
            <a href="https://wa.me/573112973336" aria-label="Find us on whatsApp" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="2.4rem" height="2.4rem" viewBox="0 0 432 432">
                <path 
                  d="M364.5 65Q427 127 427 214.5T364.5 364T214 426q-54 0-101-26L0 429l30-109Q2 271 2 214q0-87 62-149T214 3t150.5 62M214 390q73 0 125-51.5T391 214T339 89.5T214 38T89.5 89.5T38 214q0 51 27 94l4 6l-18 65l67-17l6 3q42 25 90 25m97-132q9 5 10 7q4 6-3 25q-3 8-15 15.5t-21 9.5q-18 2-33-2q-17-6-30-11q-8-4-15.5-8.5t-14.5-9t-13-9.5t-11.5-10t-10.5-10.5t-8.5-9.5t-7-8.5t-5.5-7t-3.5-5L128 222q-22-29-22-55q0-24 19-44q6-7 14-7q6 0 10 1q8 0 12 9q2 3 6 13l7 17.5l3 8.5q3 5 1 9q-3 7-5 9l-3 3l-3 3.5l-2 2.5q-6 6-3 11q13 22 30 37q13 11 43 26q7 3 11-1q12-15 17-21q4-6 12-3q6 3 36 17"
                  fill="#62a330" 
                />
              </svg>
            </a>
            <a href="https://wa.me/573112973336"><span className="text-base sm:text-lg hover:text-rgb-navbar cursor-pointer">3112973336</span></a>
          </div>
        </section>
        <div className="flex flex-row gap-x-3 items-center">
          <a href="https://www.facebook.com/profile.php?id=61552969321216&locale=es_LA" aria-label="Find us on Facebook" target="_blank" rel="noopener">
              <svg class="h-10 w-10 text-sky-800" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M48 24a24 24 0 1 0-27.75 23.7V30.95h-6.1V24h6.1v-5.29c0-6.01 3.58-9.34 9.07-9.34 2.62 0 5.37.47 5.37.47v5.91h-3.03c-2.98 0-3.91 1.85-3.91 3.75V24h6.66l-1.07 6.94h-5.59V47.7A24 24 0 0 0 48 24Z"
                      fill="currentColor"></path>
              </svg>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61552969321216&locale=es_LA"><span className="text-base sm:text-lg hover:text-rgb-navbar cursor-pointer">Pitranes SAS</span></a>
        </div>
      </li>
    </ul>
  );
}
