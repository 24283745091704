import React from "react";

export default function SectionContact({ icon, title, description }) {
  return (
    <div className="flex flex-col w-[90%] items-center my-4 sm:my-8">
      <div className="flex w-[12vh] h-[12vh] items-center justify-center rounded-full bg-zinc-200">
        <img
          src={icon}
          style={{ width: "60px", height: "80px" }}
          alt="imageContact"
        />
      </div>
      <p className="text-xl text-center font-bold text-rgb-navbar my-2">
        {title}
      </p>
      <div className="flex flex-col items-center w-[90%]">{description}</div>
    </div>
  );
}
