import React from "react";
import CustomCarousel from "../../components/carrousel/customSlider";
import MisionCard from "../../components/about/mision-card";
import SocialMedia from "../../components/about/social-media";
import { PreferCard } from "../../components/about/prefer-card";
import { DataCard } from "../../components/about/data-card";

import { mision } from "../../data/texts";
import { preferencias } from "../../data/images";
import { ImagesSlides } from "../../data/ImagesSlides";

import quienesImage from "../../assets/images/PitranesTeam/photo1.jpeg";
import teamComplete from "../../assets/images/PitranesTeam/photo3.jpeg";
import misionVehicle from "../../assets/images/PitranesTeam/mision.jpeg";
import visionVehicle from "../../assets/images/PitranesTeam/vision.jpeg";

export default function About() {
  return (
    <main className="mx-auto bg-white shadow-lg rounded-lg">
      <div className="flex justify-center py-10">
        <CustomCarousel>
          {ImagesSlides.map((ImageSlide) => {
            return (
              <img
                key={ImageSlide.id}
                src={ImageSlide.imgURL}
                alt={ImageSlide.imgAlt}
              />
            );
          })}
        </CustomCarousel>
      </div>
      <aside className="flex flex-col w-full bg-zinc-100 py-10 px-0 sm:px-20">
        <h2 className="text-3xl sm:text-5xl font-bold mb-2 flex justify-center text-rgb-navbar">
          ¿Quiénes somos?
        </h2>
        <section className="mb-1 sm:mb-4 py-4 sm:py-12 flex flex-col-reverse sm:flex-col justify-center px-1 gap-x-4 gap-y-4 sm:gap-x-10 sm:gap-y-0">
          <div className="text-black text-justify flex flex-col gap-y-2 px-6">
            <p className="text-xl sm:text-2xl">
              Somos una empresa de servicio de transporte especial, ubicada en
              Pitalito Huila, habilitada legalmente mediante la resolución
              número <strong>20234410014405</strong> del{" "}
              <strong>11 de abril de 2023</strong>, comprometida con la
              seguridad y comodidad de nuestros clientes. Nuestro equipo está
              formado por conductores capacitados y experimentados que se
              esfuerzan por ofrecer un servicio de calidad a cada uno de
              nuestros usuarios.
            </p>
            <br />
            <p className="text-xl sm:text-2xl">
              Nos especializamos en el trasporte de grupos, ya sea para{" "}
              <span className="font-bold text-rgb-navbar">
                eventos corporativos, rutas empresariales, excursiones
                turísticas{" "}
              </span>
              o{" "}
              <span className="font-bold text-rgb-navbar">
                traslados de personal de la salud.
              </span>{" "}
              Contamos con una flota de vehículos modernos y equipados para
              garantizar un viaje seguro y confortable para todos nuestros
              clientes.
            </p>
          </div>
          <div className="flex mt-8 items-center justify-center">
            <img
              src={quienesImage}
              alt="Quienes somos"
              style={{ width: "700px", borderRadius: "8px" }}
            />
          </div>
        </section>
      </aside>
      <aside className="flex flex-col w-full bg-white py-10 px-2 sm:px-20">
        <h2 className="text-3xl sm:text-5xl font-bold mb-2 flex justify-center text-rgb-navbar">
          POLÍTICA INTEGRAL
        </h2>
        <span className="text-base sm:text-2xl p-4 flex flex-col text-justify">
          Nuestra política integral esta basada en varios aspectos como la
          seguridad de los pasajeros, el mantenimiento regular de los vehículos,
          la capacitación contínua del personal, la atención al cliente y la
          sostenibilidad ambiental principalmente.
        </span>
        <div className="mb-4 py-12 px-4 flex flex-col sm:grid sm:grid-cols-2 justify-center gap-x-4 gap-y-4 sm:gap-x-10 sm:gap-y-4">
          {mision.map((misionText) => {
            return (
              <MisionCard
                key={misionText.id}
                text={misionText.text}
                icon={misionText.icon}
              />
            );
          })}
        </div>
      </aside>
      <aside className="flex flex-col w-full bg-zinc-100 py-10 px-8">
        <h2 className="text-3xl sm:text-5xl font-bold mb-2 flex text-center text-rgb-navbar">
          ¿POR QUÉ ELEGIR Y PREFERIR PITRANES SAS?
        </h2>
        <div className="mb-4 py-12 flex flex-col sm:grid sm:grid-cols-2 justify-center gap-x-8 gap-y-4 sm:gap-x-8 sm:gap-y-8">
          {preferencias.map((preferencia) => {
            return (
              <PreferCard
                key={preferencia.id}
                title={preferencia.titulo}
                description={preferencia.descripcion}
                direction={preferencia.direccion}
                icon={preferencia.icono}
              />
            );
          })}
        </div>
      </aside>
      <DataCard
        title={"MISIÓN"}
        description={
          <>
            <span>
              Nuestra misión es proporcionar servicios de transporte especial de
              pasajeros de alta calidad, seguros y confiables.
            </span>
            <br />
            <span>
              Nos esforzamos por ofrecer una excelente experiencia de viaje a
              nuestros usuarios, ofreciendoles un trato personalizado, cuidando
              de sus necesidades, comodidad y seguridad en todo momento para que
              cumplan sus objetivos.
            </span>
          </>
        }
        imgURL={misionVehicle}
        smclassRow={"sm:flex-row"}
        classRow={"flex-col"}
        bgColor={"bg-white"}
      />
      <DataCard
        title={"VISIÓN"}
        description={
          <>
            <span>
              Transporte especial de Pitalito SAS espera para el 2029
              convertirse en la empresa líder en el transporte especial de
              pasajeros de nuestra región, reconocida por su eficiencia,
              puntualidad y atención al cliente.
            </span>
            <br />
            <span>
              Buscamos crecer y expandirnos tanto a nivel local como nacional,
              ofreciendo servicios innovadores y adaptados a las necesidades
              cambiantes de nuestros usuarios, estableciendo relaciones a largo
              plazo, construyendo confianza y fidelización en cada viaje.
            </span>
          </>
        }
        imgURL={visionVehicle}
        smclassRow={"sm:flex-row-reverse"}
        classRow={"flex-col"}
        bgColor={"bg-zinc-100"}
      />
      <aside className="flex flex-col sm:flex-col bg-white text-black p-2 sm:px-8">
        <div className="flex flex-col w-full p-4 items-center justify-center">
          <h2 className="text-3xl sm:text-5xl font-bold my-6 flex text-center text-rgb-navbar">
            CONOCE NUESTRO EQUIPO
          </h2>
          <img
            src={teamComplete}
            alt="Equipo Pitranes"
            className="w-full sm:w-[80%] lg:w-[60%]"
            // style={{ width: "100%" }}
          />
          <p className="text-xl sm:text-2xl my-8 px-2 sm:px-4 flex text-justify">
            Somos un grupo coordinado y eficiente, comprometido en llevar a cada
            uno de nuestros usuarios a su destino.
          </p>
        </div>
      </aside>
      <footer className="flex flex-col bg-zinc-100 items-center py-4 my-4 sm:px-10">
        <hr />
        <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-rgb-navbar text-center">
          CONTÁCTANOS
        </h2>
        <div className="text-base sm:text-xl flex flex-col justify-start px-4 sm:px-0 gap-y-2">
          <p className="flex flex-col gap-y-4">
            <span className="flex justify-center sm:justify-start">
              ¿Tienes alguna pregunta?
            </span>
            <span className="mx-2 sm:mx-0">
              Ponte en contacto con nosotros a través de nuestros números
              <a href="https://wa.me/573214868249">
                <strong> 3214868249 </strong>
              </a>
              o al{" "}
              <a href="https://wa.me/573112973336">
                <strong>3112973336</strong>
              </a>
            </span>
          </p>
          <span className="mx-2 sm:mx-0">
            Dirección: <strong>Avenida 3 # 19-51 Piso 2. Barrio Solarte, estación de servicio Solarte </strong>{" "}
            Pitalito-Huila
          </span>
          <SocialMedia typeflex={"flex-row"} smtypeflex={"sm:flex-row"} />
        </div>
      </footer>
    </main>
  );
}
