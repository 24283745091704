import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import "./App.css";

export default function App() {
  return (
    <React.Suspense>
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    </React.Suspense>
  );
}
